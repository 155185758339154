import * as React from 'react';
import AboutCDW from '../components/aboutCDW';
import AboutCD from '../components/aboutCD';
import Hero from '../components/hero';

import Layout from '../components/layout';
import Seo from '../components/seo';
import ServiceAreas from '../components/service-areas';
import Contact from '../components/contact';

function Index() {
  return (
    <Layout>
      <Seo title="Engineering You Can Trust" />
      <Hero />
      <div id="services">
        <ServiceAreas />
      </div>
      <div id="company">
        <AboutCDW />
      </div>
      <AboutCD />
      <Contact />
    </Layout>
  );
}

export default Index;
