import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function AboutCD() {
  return (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-6xl py-16 px-4 sm:px-6 lg:py-12 lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <StaticImage
              src="../images/clayton-d-watters.jpg"
              className="sm:3/5 mx-auto flex w-1/2 object-cover lg:w-3/4"
              alt=""
            />
          </div>
          <div className="mt-10 lg:col-span-2 lg:mt-0">
            <h2 className="text-3xl font-semibold tracking-tight text-gray-900">
              Clayton D. Watters
            </h2>
            <h3 className="pb-2 text-base tracking-tight text-gray-900">
              P. Eng, MBA
            </h3>
            <p className="pb-4">
              Clayton has been in the engineering and the construction industry
              for the private sector and municipal governments for more than 35
              years. The work has included a variety of projects.
            </p>
            <p>
              Clayton is a hands on professional, performing field and
              administrative duties along with the necessary project management
              requirements.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
