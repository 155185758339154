import React from 'react';

export default function AboutCDW() {
  return (
    <div className="bg-gray-300">
      <div className="mx-auto max-w-6xl py-16 px-4 sm:px-6 lg:py-12 lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 className="text-3xl font-semibold tracking-tight text-gray-900">
              About CD Watters Engineering Ltd.
            </h2>
          </div>
          <div className="mt-2 lg:col-span-2 lg:mt-0">
            <p className="pb-4">
              We are a small, respected, highly experienced civil engineering
              company that serves
              <span className="font-semibold"> municipal governments </span> and
              <span className="font-semibold"> road authorities </span>
              through a personal consultative approach.
            </p>
            <p className="font-semibold">Our main services are:</p>
            <ul className="list-disc pl-10">
              <li>road needs studies</li>
              <li>bridge and culvert inspections</li>
              <li>
                comprehensive professional opinions and formal peer reviews
              </li>
              <li>project management.</li>
            </ul>
            <p className="py-4">
              A fundamental principle of CD Watters Engineering Ltd. is to keep
              good infrastructure in good condition; as it is always easier and
              by far the least costly approach. The primary goal is to
              collaborate to find the best solution for each project.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
