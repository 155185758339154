import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export default function ServiceAreas() {
  const data = useStaticQuery(graphql`
    query ServicesQuery {
      markdownRemark {
        frontmatter {
          services {
            description
            title
            items
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const { services } = data.markdownRemark.frontmatter;

  return (
    <div className="relative bg-gray-50 px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
      <div className="relative mx-auto max-w-6xl">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight text-gray-700 sm:text-4xl">
            Areas of Service
            <span className="mx-auto block w-10 border-b-2 border-gray-500 pt-2 text-center" />
          </h2>
        </div>
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-2">
          {services.map((post) => (
            <div key={post.title} className="flex flex-col overflow-hidden">
              <div className="flex-shrink-0 ">
                {/* <StaticImage
                  src={post.image}
                  className="h-48 w-full object-cover"
                  alt=""
                /> */}
                <GatsbyImage
                  image={post.image.childImageSharp.gatsbyImageData}
                  alt={post.title}
                  className=" aspect-square h-48 w-full object-cover"
                />
              </div>
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  <p className="text-2xl  text-gray-900">
                    {post.title}
                    <span className="block w-10 border-b-2 border-gray-500 pt-2 text-center" />
                  </p>

                  {post.description &&
                    post.description.map((text, i) => (
                      <p
                        key={i}
                        className="content-block-text mt-6 max-w-prose"
                      >
                        {text}
                      </p>
                    ))}

                  {post.items &&
                    post.items.map((item, i) => (
                      <li key={i} className="mt-2 ml-2">
                        {item}
                      </li>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
